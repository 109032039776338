import Vue from "vue";
import axios from "axios";
import VueAxios from "vue-axios";

import { store } from "@/store/index";

Vue.use(VueAxios, axios);

var crud = {
    store,
    created() {
        this.api_url = process.env.VUE_APP_API_URL;
        this.headers = {
            headers: {
                "Content-Type": "application/json",
            },
        };
        if (this.$store.state.subjekatIzabrani.mb != "0") {
            this.headers.headers[
                "Subjekat"
            ] = this.$store.state.subjekatIzabrani.mb;
        }
        if (localStorage.getItem("korisnik") !== null) {
            let korisnik = JSON.parse(localStorage.getItem("korisnik"));
            if (korisnik.token !== undefined) {
                this.headers.headers["Authorization"] =
                    "Bearer " + korisnik.token;
            }
        }

        axios.interceptors.response.use(
            function (response) {
                // Brise cache citavog sajta i ucitava novu verziju na silu.
                if (typeof response.headers["amigo-build"] !== "undefined") {
                    if (
                        "#" + response.headers["amigo-build"] !=
                        "#158"
                    ) {
                        location.reload(true);
                    }
                }
                return response;
            },
            function (error) {
                if (503 == error.response.status) {
                    window.console.log("503");
                    // localStorage.removeItem("korisnik");
                    // window.location.href = "maintenance"
                }
                if (401 == error.response.status) {
                    localStorage.removeItem("korisnik");
                    location.reload();
                }
                return Promise.reject(error);
            }
        );
    },
    methods: {
        create: async function (module, payload) {
            try {
                return axios.put(this.api_url + module, payload, this.headers);
            } catch (error) {
                console.error("lola", error);
            }
        },
        read: async function (module, uuid = null) {
            try {
                let url =
                    uuid != null
                        ? this.api_url + module + "/" + uuid
                        : this.api_url + module;
                return axios.get(url, this.headers).catch((error) => {
                    window.console.error("lola", error);
                });
            } catch (error) {
                window.console.error("lola", error);
            }
        },
        update: async function (module, uuid, payload) {
            try {
                return axios
                    .patch(
                        this.api_url + module + "/" + uuid,
                        payload,
                        this.headers
                    )
                    .catch((error) => {
                        console.error("lola", error);
                    });
            } catch (error) {
                console.error("lola", error);
            }
        },
        update_new: async function (module, payload) {
            try {
                return axios
                    .patch(this.api_url + module, payload, this.headers)
                    .catch((error) => {
                        console.error("lola", error);
                    });
            } catch (error) {
                console.error("lola", error);
            }
        },
        delete: async function (module, uuid) {
            try {
                return axios
                    .delete(this.api_url + module + "/" + uuid, this.headers)
                    .catch((error) => {
                        console.error("lola", error);
                    });
            } catch (error) {
                console.error("lola", error);
            }
        },
        list: async function (module, filter = null) {
            try {
                return axios
                    .post(this.api_url + module, filter, this.headers)
                    .catch((error) => {
                        console.error("lola", error);
                    });
            } catch (error) {
                console.error("lola", error);
            }
        },
    },
};

var CRUD = Vue.extend({
    mixins: [crud],
});

export default CRUD;
